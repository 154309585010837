import { useRouter } from 'next/router';
import { Link, AgeWithTooltip } from '@/shared/components';
import { type BlockListBlockFragment } from '@/shared/graphql/generated/graphql';
import { BoxIcon } from '@/shared/icons/large';
import { formatWithCommas } from '@/shared/utils/numbers';

interface BlockRowProps {
  block: BlockListBlockFragment;
  preview?: boolean;
}

const BlockRow = ({ block, preview }: BlockRowProps) => {
  const router = useRouter();

  return (
    <tr
      className="transition:ease-in h-[64px] font-aeonikMono duration-150 hover:cursor-pointer hover:bg-cf-gray-3"
      onClick={(e) => {
        if (e.ctrlKey || e.metaKey) {
          window.open(`/blocks/${block.id}`, '_blank');
        } else {
          router.push(`/blocks/${block.id}`);
        }
      }}
    >
      <td>
        <div className="flex space-x-2">
          <BoxIcon width="24" height="24" className=" text-cf-green-1" />
          <Link href={`/blocks/${block.id}`} underline noPropagate>
            {formatWithCommas(block.id)}
          </Link>
        </div>
      </td>
      <td>{formatWithCommas(block.events.totalCount)}</td>
      <td>{formatWithCommas(block.extrinsics.totalCount)}</td>
      {!preview && (
        <td className="w-[1%] font-aeonikMono">
          {block.validator && (
            <Link underline href={`/validators/${block.validator.account.idSs58}`} noPropagate>
              {block.validator.account.idSs58}
            </Link>
          )}
        </td>
      )}
      <td className="flex justify-end whitespace-nowrap text-cf-light-2">
        <AgeWithTooltip timestamp={block.timestamp} />
      </td>
    </tr>
  );
};

export default BlockRow;
